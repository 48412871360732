
import { defineComponent, ref } from "vue";
import { useRoute } from "vue-router";
import { hideModal } from "@/core/helpers/dom";
import mixin from "@/mixins";
import { useI18n } from "vue-i18n";
import _ from "lodash";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { CommonHtmlType } from "@/core/directive/type/common";
import { Upload } from "@element-plus/icons-vue";
import { ElFile } from "element-plus/es/components/upload/src/upload.type";
import { ElUpload } from "element-plus";
import JwtService from "@/core/services/JwtService";

export default defineComponent({
  name: "import-order-request-modal",
  props: {
    uploadUrl: {
      type: String,
      default: "",
    },
  },
  emits: ["update-list"],
  setup(props, { emit }) {
    const { t } = useI18n();

    const route = useRoute();
    const loading = ref<boolean>(false);
    const importOrderRequestRef = ref<CommonHtmlType>(null);
    const uploadRef = ref<InstanceType<typeof ElUpload>>();

    const uploadHeaders = ref({
      Authorization: `Bearer ${JwtService.getToken()}`,
      "X-LANG": JwtService.getLang(),
    });

    const { showServerErrorMsg } = mixin();

    const formData = ref({});

    const rules = ref({});

    const options = ref({});

    const setErrorUploadFile = () => {
      // formData.value.importFileList.forEach((item: any, index) => {
      //   uploadRef.value!.handleRemove(item, formData.value.importFile);
      // });
      // uploadRef.value!.clearFiles();
      // uploadRef.value!.handleStart(formData.value.importFile);
      uploadRef.value!.clearFiles();
    };

    const handleUploadError = () => {
      loading.value = false;
      setErrorUploadFile();
    };

    const handleUploading = () => {
      loading.value = true;
    };

    const handleUploadSuccess = (res, file) => {
      loading.value = false;
      if (res.code == 0) {
        Swal.fire({
          text: t("orderRequest.importSuccess"),
          icon: "success",
          buttonsStyling: false,
          confirmButtonText: t("common.okGotIt"),
          customClass: {
            confirmButton: "btn btn-primary",
          },
        }).then(() => {
          resetForm();
          emit("update-list");
          hideModal(importOrderRequestRef.value);
        });
      } else {
        showServerErrorMsg(res);
        setErrorUploadFile();
      }
    };

    const handleDiscard = () => {
      hideModal(importOrderRequestRef.value);
      resetForm();
    };

    const resetForm = () => {
      uploadRef.value!.clearFiles();
    };

    return {
      t,
      props,
      loading,
      formData,
      importOrderRequestRef,
      rules,
      options,
      uploadRef,
      handleDiscard,
      resetForm,
      handleUploadError,
      handleUploading,
      handleUploadSuccess,
      uploadHeaders,
      Upload,
    };
  },
});
