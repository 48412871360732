
import {
  defineComponent,
  getCurrentInstance,
  nextTick,
  onMounted,
  ref,
} from "vue";
import { ApiBase, ApiMerchant } from "@/core/api";
import { useI18n } from "vue-i18n";
import { ChannelOption, TaggingItem } from "@/core/directive/interface/common";
import _ from "lodash";
import { useRoute } from "vue-router";
import { getMerchantOrderProgress } from "@/core/directive/function/orderRequest";
import CommonChannelOption from "@/components/layout/CommonChannelOption.vue";
import WindowResize from "@/components/layout/WindowResize.vue";

interface Filter {
  request_number: string;
  external_order_number: string;
  order_progress: string[];
  merchant: string;
  channel: string[];
  article_no: string;
  representative: string;
  request_date_start: string;
  request_date_end: string;
}

export default defineComponent({
  components: { CommonChannelOption, WindowResize },
  name: "filter-order-request-dropdown",
  emits: ["submit-filter", "reset-filter"],
  setup(props, { emit }) {
    const { t } = useI18n();
    const route = useRoute();
    const currentInstance: any = getCurrentInstance();

    const formRef = ref<null | HTMLFormElement>(null);

    const formData = ref<Filter>({
      request_number: "",
      external_order_number: "",
      merchant: "",
      order_progress: getMerchantOrderProgress(route),
      channel: [],
      article_no: "",
      representative: "",
      request_date_start: "",
      request_date_end: "",
    });

    const options = ref({
      merchant: [] as TaggingItem[],
      merchantLoading: false,
      channel: [] as ChannelOption[],
      channelLoading: false,
      order_request_progress: [] as TaggingItem[],
    });

    const cancelReadonly = (async) => {
      removeReadonly(async);
    };

    const removeReadonly = (async) => {
      nextTick(() => {
        if (!async) {
          // ios 手机有延迟问题
          setTimeout(() => {
            Object.keys(currentInstance.ctx._.refs).forEach((item) => {
              if (item.match("select")) {
                currentInstance.ctx._.refs[item]?.$el
                  .querySelector(".el-input__inner")
                  .removeAttribute("readonly");
              }
            });
          }, 200);
        }
      });
    };

    const submit = () => {
      console.log(123);
      emit("submit-filter", formData.value);
    };

    const handleReset = () => {
      formRef.value?.resetFields();
      emit("reset-filter");
    };

    const getMerchantSourceData = async (value, id?) => {
      options.value.merchantLoading = true;
      let params = id
        ? {
            id: id,
          }
        : {
            search_value: value,
          };
      const { data } = await ApiBase.getMerchantSourceData({
        ...params,
        options: { include_fields: ["name", "country_iso_2", "address"] },
      });
      options.value.merchantLoading = false;
      if (data.code == 0) {
        options.value.merchant = data.data;
      }
    };

    const debounceMerchantSourceSearch = _.debounce(
      getMerchantSourceData,
      1000
    );

    const searchMerchantSourceItems = (query: string) => {
      debounceMerchantSourceSearch(query);
    };

    const getSalesChannelsData = async (id?, remake?) => {
      if (remake) {
        formData.value.channel = [];
        options.value.channel = [];
      }
      options.value.channelLoading = true;
      const { data } = await ApiMerchant.getSalesChannelsData({
        max_item: "all",
        merchant_id: id,
        status: "10",
      });
      options.value.channelLoading = false;
      if (data.code == 0) {
        options.value.channel = data.data;
      }
    };

    const getDropdownOptions = async () => {
      const { data } = await ApiBase.getTaggingData({
        short_key: ["order_request_progress"],
      });
      if (data.code == 0) {
        const values = getMerchantOrderProgress(route);
        if (
          route.path.match("order-request") ||
          route.path.match("order-processing")
        ) {
          data.data.order_request_progress.items.map((item) => {
            if (values.indexOf(item.value) > -1) {
              options.value.order_request_progress.push(item);
            }
          });
        } else {
          options.value.order_request_progress =
            data.data.order_request_progress.items;
        }
      }
    };

    onMounted(() => {
      getDropdownOptions();
      getMerchantSourceData("");
      cancelReadonly(false);
    });

    return {
      t,
      props,
      formData,
      formRef,
      submit,
      handleReset,
      options,
      cancelReadonly,
      getSalesChannelsData,
      searchMerchantSourceItems,
    };
  },
});
